import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import AppBar from 'material-ui/AppBar';
import classnames from 'classnames';
import {
  MenuIcon,
  Close,
  ArrowUp,
  ArrowDown,
  SignOutCircle,
} from 'Utils/SvgIcons';
import { selectCheckPermission } from 'HelperFunctions/locations';

class MobileSignedInNav extends React.Component {
  state = {
    open: false,
    dashboardOpen: false,
    eventsOpen: false,
    adminOpen: false,
    operationsOpen: false,
    profileOpen: false,
  };

  mobileSignOut = () => {
    this.toggleMobile();
    this.props.signOutUser();
  };

  toggleMobile = (event) => {
    this.setState({
      open: !this.state.open,
    });
  };

  toggleDashboard = () => {
    this.setState({
      dashboardOpen: !this.state.dashboardOpen,
    });
  };

  toggleEvents = () => {
    this.setState({
      eventsOpen: !this.state.eventsOpen,
    });
  };

  toggleOperations = () => {
    this.setState({
      operationsOpen: !this.state.operationsOpen,
    });
  };

  toggleProfile = () => {
    this.setState({
      profileOpen: !this.state.profileOpen,
    });
  };

  toggleAdmin = () => {
    this.setState({
      adminOpen: !this.state.adminOpen,
    });
  };

  openMobile = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const { location, business, checkPermission } = this.props;
    const {
      dashboardOpen,
      eventsOpen,
      adminOpen,
      operationsOpen,
      profileOpen,
      open,
    } = this.state;

    return (
      <div className='header'>
        <AppBar
          className='mobileMenu'
          title={
            <Link to='/todos' className='menuCompany'>
              {location.name}
            </Link>
          }
          showMenuIconButton={false}
          iconElementRight={this.state.open ? <Close /> : <MenuIcon />}
          onRightIconButtonClick={this.toggleMobile}
        />
        {open && (
          <div className='mobileMenuOverlay'>
            <div>
              <ul>
                <li
                  className={classnames({
                    open: dashboardOpen,
                  })}
                >
                  <a className='btnExpand' onClick={this.toggleDashboard}>
                    {dashboardOpen ? <ArrowUp /> : <ArrowDown />}
                  </a>
                  <a onClick={this.toggleDashboard}>Dashboard</a>
                  {dashboardOpen && (
                    <ul>
                      <li>
                        {checkPermission('view_edit_settings') && (
                          <Link to='/at-a-glance' onClick={this.toggleMobile}>
                            At-A-Glance
                          </Link>
                        )}
                      </li>
                      <li>
                        <Link to='/todos/orders' onClick={this.toggleMobile}>
                          To-Do's
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/dashboard/orders'
                          onClick={this.toggleMobile}
                        >
                          Schedule
                        </Link>
                      </li>
                      <li>
                        {checkPermission('view_edit_settings') && (
                          <Link to='/payments' onClick={this.toggleMobile}>
                            Payments
                          </Link>
                        )}
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  className={classnames({
                    open: eventsOpen,
                  })}
                >
                  <a className='btnExpand' onClick={this.toggleEvents}>
                    {eventsOpen ? <ArrowUp /> : <ArrowDown />}
                  </a>
                  <Link to='/orders' onClick={this.toggleMobile}>
                    Orders
                  </Link>
                  {eventsOpen && (
                    <ul>
                      <li>
                        <Link to='/orders/rentals' onClick={this.toggleMobile}>
                          Orders
                        </Link>
                      </li>
                      {checkPermission('operations_view') && (
                        <li>
                          <Link
                            to={business.permissions.subrentalManagement ? '/orders/subrentals' : '/upgrade/subrentals'}
                            onClick={this.toggleMobile}
                          >
                            Subrentals
                          </Link>
                        </li>
                      )}
                    </ul>
                  )}
                </li>
                {(checkPermission('view_inventory') ||
                  checkPermission('edit_add_inventory')) && (
                  <li>
                    <Link to='/inventory' onClick={this.toggleMobile}>
                      Inventory
                    </Link>
                  </li>
                )}
                {(checkPermission('view_contacts') ||
                  checkPermission('edit_add_contacts')) && (
                  <li>
                    <Link to='/contacts' onClick={this.toggleMobile}>
                      Contacts
                    </Link>
                  </li>
                )}

                <li
                  className={classnames({
                    open: operationsOpen,
                  })}
                >
                  <a className='btnExpand' onClick={this.toggleOperations}>
                    {operationsOpen ? <ArrowUp /> : <ArrowDown />}
                  </a>
                  <Link to='/operations' onClick={this.toggleMobile}>
                    Operations
                  </Link>
                  {operationsOpen && (
                    <ul>
                      <li>
                        <Link
                          to='/operations/calendars'
                          onClick={this.toggleMobile}
                        >
                          Calendars
                        </Link>
                      </li>
                      {checkPermission('view_edit_staffing') && (
                        <li>
                          <Link
                            to='/operations/staff'
                            onClick={this.toggleMobile}
                          >
                            Staffing
                          </Link>
                        </li>
                      )}
                      {checkPermission('view_routing') && (
                        <li>
                          <Link
                            to='/operations/routing'
                            onClick={this.toggleMobile}
                          >
                            Routing
                          </Link>
                        </li>
                      )}
                    </ul>
                  )}
                </li>
                {(checkPermission('operations_view') ||
                  checkPermission('view_edit_settings')) && (
                  <li
                    className={classnames({
                      open: adminOpen,
                    })}
                  >
                    <a className='btnExpand' onClick={this.toggleAdmin}>
                      {adminOpen ? <ArrowUp /> : <ArrowDown />}
                    </a>
                    <Link to='/admin' onClick={this.toggleMobile}>
                      Admin
                    </Link>
                    {adminOpen && (
                      <ul>
                        {checkPermission('view_edit_settings') && (
                          <li>
                            <Link
                              to='/admin/storefront'
                              onClick={this.toggleMobile}
                            >
                              Storefront
                            </Link>
                          </li>
                        )}
                        {checkPermission('operations_view') && (
                          <li>
                            <Link
                              to='/admin/marketplace'
                              onClick={this.toggleMobile}
                            >
                              Marketplace
                            </Link>
                          </li>
                        )}
                        {checkPermission('operations_view') && (
                          <li>
                            <Link
                              to='/admin/reports/quickbooks'
                              onClick={this.toggleMobile}
                            >
                              Reports
                            </Link>
                          </li>
                        )}
                        {checkPermission('view_edit_settings') && (
                          <li>
                            <Link
                              to='/admin/settings'
                              onClick={this.toggleMobile}
                            >
                              Settings
                            </Link>
                          </li>
                        )}
                        {checkPermission('view_edit_ml_settings') && (
                          <li>
                            <Link
                              to='/admin/multilocation'
                              onClick={this.toggleMobile}
                            >
                              Multilocation
                            </Link>
                          </li>
                        )}
                      </ul>
                    )}
                  </li>
                )}
                {checkPermission('operations_view') && (
                  <li>
                    <Link to='/conversations' onClick={this.toggleMobile}>
                      Messages
                    </Link>
                  </li>
                )}

                <li
                  className={classnames({
                    open: profileOpen,
                  })}
                >
                  <a className='btnExpand' onClick={this.toggleProfile}>
                    {profileOpen ? <ArrowUp /> : <ArrowDown />}
                  </a>
                  <Link to='/profile' onClick={this.toggleMobile}>
                    My Profile
                  </Link>
                  {profileOpen && (
                    <ul>
                      <li>
                        <Link to='/profile' onClick={this.toggleMobile}>
                          Info
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/profile/accounts'
                          onClick={this.toggleMobile}
                        >
                          Accounts
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/profile/googleCal'
                          onClick={this.toggleMobile}
                        >
                          Google Calendar
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/profile/notifications'
                          onClick={this.toggleMobile}
                        >
                          Notifications
                        </Link>
                      </li>
                      <li>
                        <Link
                          to='/start/get-started'
                          onClick={this.toggleMobile}
                        >
                          Get Started
                        </Link>
                      </li>
                      <li>
                        <Link to='/support' onClick={this.toggleMobile}>
                          Support
                        </Link>
                      </li>
                      <li>
                        <a
                          href='//www.tapgoods.com/pro/terms-conditions/'
                          target='_blank'
                          rel='noopener noreferrer'
                          onClick={this.toggleMobile}
                        >
                          Terms & Conditions
                        </a>
                      </li>
                      <li>
                        <a
                          href='//www.tapgoods.com/pro/privacy-policy/'
                          target='_blank'
                          rel='noopener noreferrer'
                          onClick={this.toggleMobile}
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://tapgoods-capital.webflow.io/'
                          target='_blank'
                          rel='noopener noreferrer'
                          onClick={this.toggleMobile}
                        >
                          TapGoods Capital
                        </a>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
              <div className='callouts'>
                <a onClick={this.mobileSignOut}>
                  <SignOutCircle />
                  Sign Out
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { location } = state.location;
  const { business } = state.business;
  const { user } = state.auth;
  const checkPermission = selectCheckPermission(state);

  return { location, business, user, checkPermission };
};

export default connect(mapStateToProps, actions)(MobileSignedInNav);
