import axios from 'axios';
import {
  saveAuthToken,
  getAuthToken,
  getAdminAuthToken,
  saveAdminAuthToken,
} from 'Api';
import { store } from '../components/Root';
import * as actions from 'Actions';
import { setErrors } from '../actions';

const Service = (
  headerFunc = () => {},
  saveAuthFunc = () => {},
  useInjection = false
) => {
  class WrappedService {
    service = () => {
      let service = axios.create({
        headers: headerFunc(),
      });
      service.interceptors.response.use(this.handleSuccess, this.handleError);
      return service;
    };

    handleSuccess = (response) => {
      this.handleVersioning(response);
      if (useInjection) {
        saveAuthFunc(response.headers);
      }
      return response;
    };

    handleError = (error) => {
      console.error(error, 'web request error');
      this.handleVersioning(error.response);
      if (useInjection) {
        switch (error?.response?.status) {
          case 401:
            // TODO this should call signOutUser instead of only redirecting
            this.redirectTo(document, '/login');
            break;
          case 404:
            // Sometimes this is undesired, if we fail to fetch something from api but is not the root page,
            //   we will prefer an error message instead to redirect to 404 page.
            this.redirectTo(document, '/notFound');
            break;
          default:
            store.dispatch(actions.setErrors(error.response.data.error));
            break;
        }
      }
      return Promise.reject(error);
    };

    handleVersioning = (response) => {
      if (response) {
        const headers = response.headers;
        if (
          headers['version'] &&
          headers['version'] !== process.env.REACT_APP_VERSION
        ) {
          store.dispatch(actions.openVersionModal());
        }
      }
    };

    redirectTo = (document, path) => {
      document.location = path;
    };

    get(path, params) {
      return this.service().get(path, {
        params,
      });
    }

    delete(path, options) {
      return this.service().delete(path, options);
    }

    patch(path, payload, options = {}) {
      return this.service().request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        cancelToken: options.cancelToken,
      });
    }

    put(path, payload, options = {}) {
      return this.service().request({
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
        params: options.params,
        cancelToken: options.cancelToken,
      });
    }

    post(path, payload, options = {}) {
      return this.service().request({
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
        cancelToken: options.cancelToken,
      });
    }
    all(promises) {
      return Promise.all(promises);
    }
    spread(callback) {
      return (arr) => {
        return callback.apply(null, arr);
      };
    }
  }
  return WrappedService;
};

const ServiceClass = Service(getAuthToken, saveAuthToken, true);
export default new ServiceClass();

const AdminServiceClass = Service(getAdminAuthToken, saveAdminAuthToken, true);
export const adminService = new AdminServiceClass();

const StandardServiceClass = Service();
export const standardService = new StandardServiceClass();
