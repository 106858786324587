import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'react-router-redux';
import Loadable from 'react-loadable';
import ReactGA from 'react-ga';
import * as actions from 'Actions';
import SignUp from 'Containers/authentication/SignUp';
import ReactivateBusiness from 'Containers/authentication/ReactivateBusiness';
import SelectBusiness from 'Containers/authentication/SelectBusiness';
import ConversationsMiddle from 'Components/conversations/ConversationsMiddle';
import ProfileMiddle from 'Components/profile/ProfileMiddle';
import Footer from 'Components/marketing/Footer';
import Authorization, {
  AuthAllButWarehouse,
  AuthGeneral,
} from 'Containers/authentication/Authorization';
import BusinessAuthorization from 'Containers/authentication/BusinessAuthorization';
import OverlayLoadingSpinner from 'Utils/OverlayLoadingSpinner';
import NotFound from 'Utils/NotFound';
import ApplicationError from 'Utils/ApplicationError';
import CompliancePopUp from 'Utils/CompliancePopUp';
import SystemUpdateWidget from 'Utils/global/SystemUpdateWidget';
import Helmets from 'Utils/Helmets';
import RouteChangeWrapper from 'Utils/RouteChangeWrapper';
import UnsupportedRoute from 'Utils/UnsupportedRoute';
import { isSupportedBrowser } from 'HelperFunctions/general';
import App from './App';
import Home from './marketing/Home';
import HelpForm from './marketing/HelpForm';
import PrintMiddle from './print/PrintMiddle';
import Email from './emails/Email';
import NewFeedback from './feedbacks/NewFeedback';
import FeedbackThankYou from './feedbacks/FeedbackThankYou';
import ContactUs from './marketing/ContactUs';
import TopHeader from 'Utils/global/TopHeader';
import { connect } from 'react-redux';
import { logOutAfterOtherTabSignIn } from 'Actions';
import { ADMIN_ONLY_ROLES } from 'Utils/AtAGlanceLink';
import ImportWizardUpload from './import_wizard/Upload';
import ImportWizardAdjust from './import_wizard/Adjust';
import ImportWizardSelect from './import_wizard/ImportWizardSelect';
import ImportWizardDone from './import_wizard/Done';
const SignIn = Loadable({
  loader: () => import('../containers/authentication/SignIn'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});

const BusinessPaymentPage = Loadable({
  loader: () => import('Components/transactions/BusinessPaymentPage'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});

const BusinessPaymentStatusPage = Loadable({
  loader: () => import('Components/transactions/BusinessPaymentStatusPage'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});

const ForgotPassword = Loadable({
  loader: () => import('Containers/authentication/ForgotPassword'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const PasswordReset = Loadable({
  loader: () => import('Containers/authentication/PasswordReset'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const SignUpMiddle = Loadable({
  loader: () => import('./signup/SignUpMiddle'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Dashboard = Loadable({
  loader: () => import('./dashboard/Dashboard'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Tutorials = Loadable({
  loader: () => import('./tutorials/Tutorials'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Notifications = Loadable({
  loader: () => import('./notifications/Notifications'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const ChurnZeroSuccessCenter = Loadable({
  loader: () => import('./churn_zero/ChurnZeroSuccessCenter'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const AtAGlance = Loadable({
  loader: () => import('./dashboard/AtAGlance'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Payments = Loadable({
  loader: () => import('./payments/PaymentsMiddle'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Events = Loadable({
  loader: () => import('./events/Events.routes'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Inventory = Loadable({
  loader: () => import('./products/Inventory'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Contacts = Loadable({
  loader: () => import('./contacts/Contacts'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Operations = Loadable({
  loader: () => import('./operations/Operations'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Staff = Loadable({
  loader: () => import('Components/staff/Staff'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Services = Loadable({
  loader: () => import('Components/services/Services'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const BusinessAdmin = Loadable({
  loader: () => import('Components/business_admin/BusinessAdmin'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Admin = Loadable({
  loader: () => import('Components/admin/Admin'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Calendars = Loadable({
  loader: () => import('Components/calendars/CalendarContainer'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Recurrences = Loadable({
  loader: () => import('./recurrences/Recurrences'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});
const Upgrade = Loadable({
  loader: () => import('./upgrade/Upgrade'),
  loading() {
    return <OverlayLoadingSpinner />;
  },
});

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function logPageView() {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const AuthFeedback = AuthAllButWarehouse(NewFeedback);

const AuthAdminOnly = Authorization(ADMIN_ONLY_ROLES);

export const AuthStandardTier = BusinessAuthorization(['all']);
export const AuthServiceTier = BusinessAuthorization(['services']);

export const history = createBrowserHistory();
history.listen(() => {
  logPageView();
});

const routesWithSystemUpdateWidget = [
  '/dashboard',
  '/todos',
  '/payments',
  '/conversations',
  '/profile',
  '/operations',
  '/services',
  '/calendars',
  '/contacts',
  '/inventory',
  '/orders',
  '/parent-orders',
  '/admin',
];
const routesWithCompliancePopUp = [
  '/dashboard',
  '/todos',
  '/payments',
  '/conversations',
  '/profile',
  '/operations',
  '/services',
  '/calendars',
  '/contacts',
  '/inventory',
  '/orders',
  '/parent-orders',
  '/admin',
];

const routesWithFooter = [
  '/feedbacks',
  '/test',
  '/login',
  '/forgotPassword',
  '/passwordReset',
  '/selectBusiness',
  '/signUp',
  '/start',
  '/support',
  '/contact',
  '/notFound',
  '/parent-orders',
  '/applicationError',
];

class Routes extends Component {
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillMount() {
    this.restoreInfoFromLocal();
  }

  restoreInfoFromLocal = () => {
    const { store } = this.props;
    const tileView = localStorage.getItem('tileView') || 'false';
    const itemNotesView = localStorage.getItem('itemNotesView') || 'false';

    if (tileView === 'false') {
      store.dispatch(actions.hideTiles());
    } else {
      store.dispatch(actions.showTiles());
    }

    if (itemNotesView === 'false') {
      store.dispatch(actions.hideItemNotes());
    } else {
      store.dispatch(actions.showItemNotes());
    }
  };
  localStorageUpdated = () => {
    const { store, user } = this.props;
    if (user && user.uid) {
      const authTokenHeaders = JSON.parse(
        localStorage.getItem('authTokenHeaders')
      );
      if (authTokenHeaders && user.uid !== authTokenHeaders.uid) {
        store.dispatch(logOutAfterOtherTabSignIn());
        history.push({
          pathname: '/login',
        });
      }
    }
  };
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', this.localStorageUpdated);
    }
  }

  render() {
    const { shouldShowAlertTrial } = this.props;

    if (!isSupportedBrowser()) {
      return <UnsupportedRoute history={history} />;
    }
    return (
      <ConnectedRouter history={history}>
        <RouteChangeWrapper>
          <div className='container'>
            <Route path='/' component={App} />
            <header>
              <TopHeader />
              {routesWithSystemUpdateWidget.map((path, index) => (
                <Route key={index} path={path} component={SystemUpdateWidget} />
              ))}
              {routesWithCompliancePopUp.map((path, index) => (
                <Route key={index} path={path} component={CompliancePopUp} />
              ))}

              <Route path='/' component={Helmets} />
            </header>
            <main className={shouldShowAlertTrial ? 'm-t-30' : ''}>
              <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/feedbacks' component={NewFeedback} />
                <Route path='/feedbacks/new' component={NewFeedback} />
                <Route
                  path='/feedbacks/thankyou'
                  component={FeedbackThankYou}
                />
                <Route path='/test' component={AuthFeedback} />
                <Route path='/login' component={SignIn} />
                <Route path='/signUp' component={SignUp} />
                <Route
                  path='/billingInfo/:businessId/:paymentToken'
                  component={BusinessPaymentPage}
                />
                <Route
                  path='/paymentStatus/:businessId/:clientSecret'
                  component={BusinessPaymentStatusPage}
                />
                <Route path='/start' component={SignUpMiddle} />
                <Route path='/contact' component={ContactUs} />
                <Route path='/reactivate' component={ReactivateBusiness} />
                <Route path='/selectBusiness' component={SelectBusiness} />
                <Route path='/forgotPassword' component={ForgotPassword} />
                <Route path='/passwordReset' component={PasswordReset} />
                <Route exact path='/emails/:id' component={Email} />
                <Route path='/support' component={AuthGeneral(HelpForm)} />
                <Route path='/dashboard' component={AuthGeneral(Dashboard)} />
                <Route path='/tutorials' component={AuthGeneral(Tutorials)} />
                <Route path='/todos' component={AuthGeneral(Notifications)} />
                <Route
                  path='/churn_zero_success_center'
                  component={AuthGeneral(ChurnZeroSuccessCenter)}
                />
                <Route
                  exact
                  path='/importWizard/upload/:dataType'
                  component={AuthGeneral(ImportWizardUpload)}
                />
                <Route
                  exact
                  path='/importWizard/adjust/:dataType'
                  component={AuthGeneral(ImportWizardAdjust)}
                />
                <Route
                  exact
                  path='/importWizard/done/:dataType'
                  component={AuthGeneral(ImportWizardDone)}
                />
                <Route
                  exact
                  path='/importWizard'
                  component={AuthGeneral(ImportWizardSelect)}
                />
                <Route
                  path='/at-a-glance'
                  component={AuthAdminOnly(AtAGlance)}
                />
                <Route path='/payments' component={AuthGeneral(Payments)} />
                <Route path='/conversations' component={ConversationsMiddle} />
                <Route path='/profile' component={ProfileMiddle} />
                <Route exact path='/upgrade/:toUpgrade'
                  component={AuthGeneral(Upgrade)} />
                  <Route exact path='/upgrade'
                    component={AuthGeneral(Upgrade)} />
                <Route
                  path='/operations'
                  component={AuthGeneral(AuthStandardTier(Operations))}
                />
                <Route
                  path='/services'
                  component={AuthGeneral(AuthServiceTier(Staff))}
                />
                <Route
                  path='/services'
                  component={AuthGeneral(AuthServiceTier(Services))}
                />
                <Route
                  path='/recurrences'
                  component={AuthGeneral(AuthServiceTier(Recurrences))}
                />
                <Route
                  path='/calendars'
                  component={AuthGeneral(AuthServiceTier(Calendars))}
                />
                <Route path='/contacts' component={Contacts} />
                <Route
                  path='/inventory'
                  component={AuthGeneral(AuthStandardTier(Inventory))}
                />
                <Route path='/orders' component={AuthGeneral(Events)} />
                <Route path='/admin' component={BusinessAdmin} />
                <Route path='/notFound' component={NotFound} />
                <Route path='/applicationError' component={ApplicationError} />

                <Route path='/print' component={AuthGeneral(PrintMiddle)} />
                <Route path='/tg-admin' component={Admin} />
                <Redirect from='*' to='notFound' />
              </Switch>
            </main>
            {routesWithFooter.map((path, index) => (
              <Route key={index} path={path} component={Footer} />
            ))}
          </div>
        </RouteChangeWrapper>
      </ConnectedRouter>
    );
  }
}
const mapStateToProps = (state) => {
  const { user } = state.auth;
  const { user: adminUser } = state.admin_auth;
  const { alertsTrial, shouldShowAlertTrial } = state.business;
  return { user, adminUser, alertsTrial, shouldShowAlertTrial };
};
export default connect(mapStateToProps, null)(Routes);
