import classnames from 'classnames';
import {
  Alert,
  CheckOnCircle,
  Connect,
  DollarsCircle,
  StatusCircle,
  TempItem,
  WithSubRentals,
} from 'Utils/SvgIcons';
import React from 'react';
import { parseQuery, stringify } from 'HelperFunctions/QueryString';
import moment from 'moment';
import DraggableToolTip from 'Utils/DraggableToolTip';
import { allowedRolesForPermission } from 'HelperFunctions/locations';
import Morning from '../images/icons/Morning.svg';
import Night from '../images/icons/Night.svg';
import Clock from '../images/icons/Clock.svg';

export const customersString = (rental) => {
  const customers = rental.customers
    ? rental.customers
    : rental.customerRentalRelationships.filter((cr) => {
      if (cr.isPrimaryContact == false) return cr.client;
    });
  const customerName = customers.map((customer, index) => {
    return customer.client
      ? customer.client.firstName || customer.client.name
      : '';
  });
  const temp = customerName.join(', ');
  const primaryCustomer = rental.customerRentalRelationships.find(
    (cr) => cr.isPrimaryContact
  );

  if (
    rental.companyRentalRelationship &&
    rental.companyRentalRelationship.isPrimaryContact
  ) {
    return temp
      ? `${rental.companyRentalRelationship.company.name}, ${temp}`
      : rental.companyRentalRelationship.company.name;
  } else if (primaryCustomer) {
    return temp
      ? `${primaryCustomer.client.firstName}, ${temp}`
      : primaryCustomer.client.firstName;
  } else {
    return temp;
  }
};

export const isItem = (item) =>
  item.type === 'RentalItemTemporary' || item.type === 'RentalItemStandard';
export const renderItemQty = (item, quantity = 0) => {
  const subRentalQty = item.subRental ? item.subRental.quantity : 0;
  if (subRentalQty > 0) {
    return `(${subRentalQty}) ${item.quantity}`;
  } else {
    return quantity || item.quantity;
  }
};

export const renderSubTotals = (item, quantity, rental) => {
  // The following function return time multiple, hours in case of hourly price , weeks in case of weekly price and same for months
  // Don't get confused by the name 'hours'
  let hours = getHoursFromSchedule(item, rental);
  let selectedPrice = '';
  let selectedPriceBeforeDiscount = '';

  if (item.period === 'advanced_pricing') {
    hours = 1;
    quantity = 1;
  }

  let periodPrice = 0;
  let periodPriceAfterDiscount = 0;
  if (isItem(item)) {
    periodPrice = parseFloat(Math.round(item.periodPriceRaw * 100) / 100);
    periodPriceAfterDiscount = parseFloat(
      Math.round(item.periodPriceAfterDiscountRaw * 100) / 100
    );

    if (periodPrice !== periodPriceAfterDiscount) {
      selectedPrice =
        item.selectedPrice ||
        (periodPriceAfterDiscount * quantity * hours).toString();
      selectedPriceBeforeDiscount = (periodPrice * quantity * hours).toString();
    } else {
      selectedPrice = (periodPrice * quantity * hours).toString();
      selectedPriceBeforeDiscount = selectedPrice;
    }
  } else {
    selectedPrice = (quantity / item.quantity) * item.selectedPrice;
    selectedPriceBeforeDiscount =
      (quantity / item.quantity) * item.selectedPrice;
  }

  return { selectedPrice, selectedPriceBeforeDiscount };
};

export const isQuoteExpired = (rental) => {
  if (rental.expireDate) {
    const expireDate = new Date(rental.expireDate);
    const today = new Date();
    return expireDate < today;
  } else {
    return false;
  }
};

const roundDecimalLargerthan = (value) => {
  if (
    ['1', '9'].indexOf(
      parseFloat(value)
        .toFixed(2)
        .split('.')[1][1]
    ) >= 0
  ) {
    return Math.round(value);
  } else {
    return value;
  }
};

export const extractFeeFromPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  if (item.rentalItemFeeRelationships.length > 0) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType === 'amount') {
        // Following Line checks if qunantity is split so it extract fee only for the splitted quantity
        const feeAmount = (+rifr.amount / item.quantity) * quantity;
        priceWithFees =
          priceWithFees -
          feeAmount * (item.period !== 'edited_flat_price' ? quantity : 1);
      } else if (rifr.valueType === 'percentage') {
        priceWithFees = priceWithFees - basePrice * +rifr.amount;
      }
    });
  }
  return parseFloat(Math.round(priceWithFees * 100) / 100).toFixed(2);
};

export const extractFeeFromSelectedPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  if (item.rentalItemFeeRelationships) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType == 'amount') {
        priceWithFees = priceWithFees - +rifr.amount * quantity;
      } else if (rifr.valueType == 'percentage') {
        priceWithFees = priceWithFees - basePrice * +rifr.amount;
      }
    });
  }
  return parseFloat(Math.round(priceWithFees * 100) / 100).toFixed(2);
};

export const extractFlatFeeFromSelectedPrice = (
  item,
  basePrice,
  priceWithFees,
  quantity
) => {
  if (item.rentalItemFeeRelationships) {
    item.rentalItemFeeRelationships.forEach((rifr) => {
      if (rifr.valueType == 'amount') {
        priceWithFees = priceWithFees - +rifr.amount;
      } else if (rifr.valueType == 'percentage') {
        let amount = (+(basePrice * +rifr.amount).toFixed(3)).toFixed(2);
        priceWithFees = priceWithFees - +amount;
      }
    });
  }

  return parseFloat(Math.floor(priceWithFees * 100) / 100).toFixed(2);
};

export const RenderItemSpecificFee = ({ item, className }) => {
  return (
    <td className={className}>
      {item.rentalItemFeeRelationships &&
        item.rentalItemFeeRelationships.length > 0 && (
          <div>
            <DraggableToolTip
              tooltipContent={<p>This item has an associated fee.</p>}
              tooltipProps={{
                id: 'itemFee-tip',
                class: 'tooltip',
                effect: 'solid',
              }}
              linkProps={{
                className: 'info',
                'data-tip': true,
                'data-for': 'itemFee-tip',
                'data-event': 'mouseenter',
                'data-event-off': 'mouseleave',
              }}
            >
              <DollarsCircle />
            </DraggableToolTip>
          </div>
        )}
    </td>
  );
};

export const setRentalFilterParams = (filter, location, history = null) => {
  if (filter.numberPer) {
    localStorage.setItem('numberPer', filter.numberPer);
  }

  const query = parseQuery(window.location.search);
  const newFilter = {
    ...query,
    ...filter,
  };
  const stringified = stringify(newFilter);
  history.push({
    pathname: location.pathname,
    search: `?${stringified}`,
    state: { prioritize_sticky: false, resetFilters: true },
    // this causes component to rerender. we need to know if that rerender was caused by this method or actually changes pages.
  });
};

export const renderAlert = (rental, location = {}) => {
  const orangeAlert = ['reservation', 'in_use'];
  if (rental.approvalStatus === 'pending' || rental.approvalStatus === 'lead') {
    return <Alert className='orange' />;
  } else if (
    rental.hasShortage &&
    !orangeAlert.includes(rental.status) &&
    location.showSoftHolds
  ) {
    return <Alert className='medlightgrey' />;
  } else if (rental.hasShortage) {
    return <Alert className='orange' />;
  } else if (rental.hasSoftHold) {
    if (!location.showSoftHolds) return null;
    return <Alert className='medlightgrey' />;
  } else if (rental.connecActionRequired) {
    // replace with correct icon
    return <Connect />;
  } else if (
    rental.subRentalStatus !== 'No SubRentals' &&
    rental.subRentalStatus !== 'active'
  ) {
    // replace with correct icon
    return (
      <WithSubRentals
        className={classnames({
          orange: rental.subRentalStatus === 'warning',
          medlightgrey: rental.subRentalStatus === 'pending',
          green: rental.subRentalStatus === 'ready',
          blue: rental.subRentalStatus === 'exists',
        })}
      />
    );
  } else if (
    rental.alerting &&
    rental.status === 'quote' &&
    location.showSoftHolds
  ) {
    return <Alert className='medlightgrey' />;
  } else if (rental.alerting) {
    return <Alert className='orange' />;
  } else {
    return null;
  }
};

export const renderSubRentalType = (source) => {
  if (source === 'connect') {
    return <Connect className='medlightgrey' />;
  } else if (source === 'temporary') {
    return <TempItem className='medlightgrey' />;
  } else {
    return <WithSubRentals className='medlightgrey' />;
  }
};

export const rentalsFilterFromPropsAndState = (
  state,
  ownProps,
  includeReduxFilter = true,
  initialFilter = null
) => {
  const { filter } = state.rentalsList;
  const { location, includeDeliveryTickets } = ownProps;
  let numberPerFromLocal = localStorage.getItem('numberPer');

  if (numberPerFromLocal === 'undefined' || numberPerFromLocal === null) {
    numberPerFromLocal = 10;
  }
  const query = parseQuery(window.location.search);
  const newInitialFilter = initialFilter
    ? initialFilter
    : {
      startDate: null,
      endDate: null,
      date: null,
      source: '',
      softwareTier: '',
      storefrontTier: '',
      chargeType: '',
      revenueType: '',
      paymentType: '',
      paymentMethod: '',
      salesperson: '',
      pickedStatus: '',
      checkedInStatus: '',
      status: '',
      pickupType: '',
      shippingType: '',
      truckIds: '',
      subRentalSource: '',
      deliveryType: '',
      deliveryTime: '',
      startDeliveryDate: null,
      endDeliveryDate: null,
      eventTiming: '',
      dateType: 'updated_at',
      numberPer: +numberPerFromLocal,
      includeDeliveryTickets: false,
      contactCategories: '',
      departmentIds: '',
      pickListStatus: '',
      sortDirection: '',
      sortField: '',
    };
  const {
    startDate,
    endDate,
    date,
    startDeliveryDate,
    endDeliveryDate,
    page,
    search,
    containingDate,
    truckIds,
    sortField,
    sortDirection,
    ...otherFromQuery
  } = query;

  const newFilter = Object.assign(
    newInitialFilter,
    includeReduxFilter && filter,
    {
      ...otherFromQuery,
      query: search,
      showAll: false,
      startDate: startDate ? new Date(startDate) : null,
      endDate: endDate ? new Date(endDate) : null,
      date: date ? new Date(date) : null,
      startDeliveryDate: startDeliveryDate ? new Date(startDeliveryDate) : null,
      endDeliveryDate: endDeliveryDate ? new Date(endDeliveryDate) : null,
      page: page ? Number(page) : 1,
      containingDate: containingDate ? new Date(containingDate) : null,
      truckIds: truckIds ? truckIds : '',
      sortField: sortField || 'start_date',
      sortDirection: sortDirection || 'asc',
      includeDeliveryTickets: includeDeliveryTickets,
    },
    filter.topLevelFilter === 'availability' && {
      selectedDate: state.calendars.selectedDate,
    }
  );

  return newFilter;
};

export const rentalInventoriesFilterFromPropsAndState = (
  state,
  includeReduxFilter = true,
  initialFilter = null
) => {
  const { filter } = state.rentalInventoryList;

  let numberPerFromLocal = localStorage.getItem('numberPer');

  if (numberPerFromLocal === 'undefined' || numberPerFromLocal === null) {
    numberPerFromLocal = 10;
  }

  const query = parseQuery(window.location.search);
  const newInitialFilter = initialFilter
    ? initialFilter
    : {
      startDate: null,
      endDate: null,
      startDeliveryDate: null,
      endDeliveryDate: null,
      source: '',
      softwareTier: '',
      storefrontTier: '',
      chargeType: '',
      revenueType: '',
      paymentType: '',
      paymentMethod: '',
      salesperson: '',
      pickedStatus: '',
      checkedInStatus: '',
      status: '',
      rentalStatus: '',
      pickupType: '',
      shippingType: '',
      truckIds: '',
      subRentalSource: '',
      deliveryType: '',
      deliveryTime: '',
      eventTiming: '',
      dateType: 'updated_at',
      itemId: '',
      numberPer: +numberPerFromLocal,
      listSortBy: 'department',
      pickListStatus: '',
    };
  const {
    startDate,
    endDate,
    startDeliveryDate,
    endDeliveryDate,
    page,
    search,
    containingDate,
    truckIds,
    itemId,
    rentalStatus,
    pickedStatus,
    sortField,
    sortDirection,
    pickListStatus,
    ...otherFromQuery
  } = query;
  return Object.assign(newInitialFilter, includeReduxFilter && filter, {
    ...otherFromQuery,
    query: search,
    showAll: false,
    startDate: startDate ? new Date(startDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    startDeliveryDate: startDeliveryDate ? new Date(startDeliveryDate) : null,
    endDeliveryDate: endDeliveryDate ? new Date(endDeliveryDate) : null,
    page: page ? Number(page) : 1,
    containingDate: containingDate ? new Date(containingDate) : null,
    truckIds: truckIds ?? '',
    itemId: itemId ?? null,
    rentalStatus: rentalStatus,
    sortField: sortField ?? 'start_date',
    sortDirection: sortDirection ?? 'asc',
    pickedStatus: pickedStatus ?? '',
    pickListStatus: pickListStatus ?? '',
  });
};

export const renderPickedStatus = (pickedStatus) => {
  switch (pickedStatus) {
    case 'alert':
      return <Alert />;
    case 'full':
      return <CheckOnCircle />;
    default:
      return <StatusCircle />;
  }
};

export const getSortClass = (fieldName, sortField, sortDirection) => {
  if (fieldName === sortField) {
    return 'sortable ' + sortDirection;
  } else {
    return 'sortable';
  }
};

export const humanizeTimeLeftToExpire = (seconds) => {
  if (seconds > 0) {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / (1000 * 60));
    let string = '';
    if (hours > 0) {
      string += hours + 'hrs';
    }
    if (minutes > 0) {
      string += ' ' + minutes + 'min';
    }
    return string;
  } else {
    return '';
  }
};

export const actionStatus = (rental) => {
  if (rental.cancelRequested) {
    return 'cancelRequested';
  } else {
    if (
      rental.changeRequestStatus === 'pending' &&
      rental.status !== 'cancelled'
    ) {
      if (
        rental.approvalStatus === 'pending' ||
        rental.approvalStatus === 'lead'
      ) {
        return 'pending';
      } else {
        return 'changeRequest';
      }
    } else if (
      rental.approvalStatus !== 'approved' &&
      rental.status !== 'cancelled' &&
      rental.status !== 'closed'
    ) {
      if (
        rental.approvalStatus === 'pending' ||
        rental.approvalStatus === 'lead'
      ) {
        return 'pending';
      } else {
        return 'none';
      }
    } else {
      switch (rental.status) {
        case 'quote':
          if (isQuoteExpired(rental)) {
            return 'quoteExpired';
          } else {
            if (rental.documentExpired) {
              return 'documentExpired';
            } else if (!rental.depositComplete && !rental.sigComplete) {
              return 'quoteRequestBoth';
            } else if (!rental.depositComplete) {
              return 'quoteRequestDeposit';
            } else if (!rental.sigComplete) {
              return 'quoteRequestSignature';
            } else {
              return 'quoteForceReservation';
            }
          }
        case 'reservation':
          return 'reservation';
        case 'in_use':
          return 'in_use';
        case 'checked_in':
          return 'checked_in';
        case 'closed':
          return 'closed';
        case 'cancelled':
          return 'cancelled';
        default:
          return 'none';
      }
    }
  }
};

export const isRecipientOpendEmail = (recipient, openedRecipients) => {
  let opened = false;

  openedRecipients.forEach((or) => {
    if (or && (or.indexOf(recipient) !== -1 || recipient.indexOf(or) !== -1)) {
      opened = true;
    }
  });

  return opened;
};

export const checkSoftHold = (rentalInventory, location) => {
  return rentalInventory.hasSoftHold && location.showSoftHolds;
};

export const checkIfSoftHoldIsOff = (rentalInventory, location) => {
  return rentalInventory.hasSoftHold && !location.showSoftHolds;
};

export const hasDimensions = (product) => {
  return (
    product.warehouseLocation ||
    product.weight ||
    product.lengthFt ||
    product.length ||
    product.widthFt ||
    product.width ||
    product.heightFt ||
    product.height
  );
};

export const renderDimensions = (
  product,
  displayLocation = true,
  classNames = {}
) => {
  if (Object.keys(classNames).length === 0) {
    classNames.parentDiv = 'highlight';
  }

  return (
    <div className={classNames.parentDiv}>
      {displayLocation && product.warehouseLocation && (
        <div>{product.warehouseLocation}</div>
      )}
      {product.weight && (
        <div>
          {product.weight}
          lbs
        </div>
      )}
      {(product.lengthFt ||
        product.length ||
        product.widthFt ||
        product.width ||
        product.heightFt ||
        product.height) && (
          <div>
            {(product.lengthFt || product.length) && (
              <span>
                {'L:'}
                {product.lengthFt ? `${product.lengthFt}' ` : ''}
                {product.lengthFt && product.length && ' '}
                {product.length ? `${product.length}"` : ''}
              </span>
            )}
            {(product.widthFt || product.width) && (
              <span>
                {(product.lengthFt || product.length) && ' x '}
                {'W:'}
                {product.widthFt ? `${product.widthFt}' ` : ''}
                {product.widthFt && product.width && ' '}
                {product.width ? `${product.width}"` : ''}
              </span>
            )}
            {(product.heightFt || product.height) && (
              <span>
                {(product.lengthFt ||
                  product.length ||
                  product.widthFt ||
                  product.width) &&
                  ' x '}
                {'H:'}
                {product.heightFt ? `${product.heightFt}' ` : ''}
                {product.heightFt && product.height && ' '}
                {product.height ? `${product.height}"` : ''}
              </span>
            )}
          </div>
        )}
    </div>
  );
};

export const renderDimensionsForPickList = (product) => {
  const classNames = {
    parentDiv: 'picklist-dimensions',
  };
  return renderDimensions(product, false, classNames);
};

export const isFinalBalanceDue = (rental) => {
  const { billingStatus, finalPaymentDueDate } = rental;
  if (
    (billingStatus === 'None' || billingStatus === 'Partial') &&
    finalPaymentDueDate &&
    moment(new Date()).isAfter(finalPaymentDueDate, 'day')
  ) {
    return true;
  }
  return false;
};

export const itemQuantityNeeded = (
  totalNeeded,
  quantity,
  maintenanceQuantity = 0
) => {
  let value = totalNeeded - quantity - maintenanceQuantity;
  return value;
};
export const convertHoursTo = (unit, hours) => {
  if (hours > 0) {
    let newTime;
    switch (unit) {
      case 'half_day_price':
        newTime = Math.round(hours / 12);
        break;
      case 'daily_price':
        newTime = Math.round(hours / 24);
        break;
      case 'weekly_price':
        newTime = Math.round(hours / 24 / 7);
        break;
      case 'monthly_price':
        newTime = Math.round(hours / 24 / 30);
        break;
      default:
        newTime = Math.round(hours);
        break;
    }
    newTime = newTime <= 0 ? 1 : newTime;
    return newTime;
  } else {
    return 1;
  }
};

export const getHoursFromSchedule = (item, rental) => {
  let hours = 1;

  if (
    rental &&
    rental.schedule &&
    [
      'none_selected',
      'flat_price',
      'flat_unit_price',
      'edited_flat_price',
      'edited_flat_unit_price',
    ].indexOf(item.period) < 0 &&
    (item.productType === 'items' || item.productType === 'bundles') &&
    !item.period.includes('standard_flat_price')
  ) {
    let startTime = moment(rental.schedule.eventStartDate);
    let endTime = moment(rental.schedule.eventEndDate);
    hours = moment.duration(endTime.diff(startTime)).asHours();
    hours = convertHoursTo(item.period, hours);
  }
  return hours;
};

export const rentalLink = (employeeLocationRelationship, location, rental) => {
  const link =
    rental.status === 'draft' || rental.status === 'template'
      ? '/orders/rentals/' + rental.token + '/edit'
      : '/orders/rentals/' + rental.token;

  const devopsLink = '/orders/rentals/' + rental.token + '/pickList';
  return employeeLocationRelationship &&
    !allowedRolesForPermission(location, 'operations_view')
      .split(',')
      .some((roleKey) =>
        employeeLocationRelationship.roleKeys?.includes(roleKey)
      )
    ? devopsLink
    : link;
};

export const displayTimeIcon = (rt) => {
  if (rt.transportType == 'drop_off') {
    const eventStartSetting =
      rt.eventStartSetting || rt.rental?.schedule?.eventStartSetting;
    return getDeliveryTimeIcon(eventStartSetting);
  } else {
    const eventEndSetting =
      rt.eventEndSetting || rt.rental?.schedule?.eventEndSetting;
    return getDeliveryTimeIcon(eventEndSetting);
  }

  return null;
};

export const getDeliveryTimeIcon = (key) => {
  const mappings = {
    morning: Morning,
    evening: Night,
    clock: Clock,
  };

  return <img className='marginImg' src={mappings[key]} />;
};
