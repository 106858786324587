import { stringify, parseQuery } from "HelperFunctions/QueryString";

export const setFilterParams = (filter, location, history = null) => {
  const query = parseQuery(window.location.search);
  const newFilter = {
    ...query,
    ...filter
  };
  const stringified = stringify(newFilter);
  history.push({
    pathname: location.pathname,
    search: `?${stringified}`
  });
};

export const filterFromPropsAndState = (
  props,
  reduxFilter = {},
  includeReduxFilter = false
) => {
  const { location } = props;
  const parsed = parseQuery(window.location.search);
  const { page } = parsed;

  let numberPerFromLocal = localStorage.getItem("numberPer");
  if (numberPerFromLocal === "undefined" || numberPerFromLocal === null) {
    numberPerFromLocal = 10;
  }
  const newFilter = Object.assign(
    { numberPer: numberPerFromLocal },
    includeReduxFilter && reduxFilter,
    {
      ...parsed,
      page: page ? Number(page) : 1,
    }
  );
  localStorage.setItem("numberPer", newFilter.numberPer);
  return newFilter;
};

export const convertNameToUrl = name => {
  return name
    .split(" ")
    .join("-")
    .replace(/[^a-zA-Z0-9-]+/g, "")
    .toLowerCase();
};

export const paymentsUrl = paymentProcessor => {
  return {
    propay: "/admin/settings/propayPaymentInfo",
    worldpay: "/admin/settings/worldpayPaymentInfo",
    stripe: "/admin/settings/paymentInfo",
    launchpay: "/admin/settings/worldpayPaymentInfo"
  }[paymentProcessor];
};
