import {
  FETCH_RENTAL_LIST_REQUEST,
  FETCH_RENTAL_LIST_SUCCESS,
  EPIC_ERROR,
} from 'Constants/redux';
import service from 'Api/service';
import Rx from 'rxjs/Rx';
import moment from 'moment-timezone';
const { Observable } = Rx;

const fetchRentalListEpic = (action$) =>
  action$
    .ofType(FETCH_RENTAL_LIST_REQUEST)
    .debounceTime(500)
    .switchMap((action) => {
      const {
        filter,
        numberPer,
        startDate,
        endDate,
        startDeliveryDate,
        endDeliveryDate,
        query,
        deliveryType,
        deliveryTime,
        billingStatus,
        truckIds,
        salesperson,
        source,
        zone,
        status,
        pickedStatus,
        checkedInStatus,
        selectedDate,
        customerId,
        companyId,
        eventTiming,
        sortField,
        sortDirection,
        page,
        includeDeliveryTickets,
        contactCategories,
        prioritize_sticky,
        multilocationAllLocations,
        departmentIds,
        pickListStatus,
      } = action.params;

      let orderStatus = status;
      if (status.includes(',')) {
        orderStatus = status.split(',');
      }

      const timeZone = moment.tz.guess();
      return Rx.Observable.fromPromise(
        service.get(
          process.env.REACT_APP_API_DOMAIN + '/api/rentals/paginated',
          {
            per: numberPer,
            filter: filter,
            billing_status: billingStatus,
            truck_ids: truckIds,
            salesperson: salesperson,
            source: source,
            start_date: startDate,
            end_date: endDate,
            start_delivery_date: startDeliveryDate,
            end_delivery_date: endDeliveryDate,
            time_zone_offset: zone,
            time_zone: timeZone,
            department_ids: departmentIds,
            pick_list_status: pickListStatus,
            rental_status: orderStatus,
            picked_status: pickedStatus,
            checked_in_status: checkedInStatus,
            selectedDate: selectedDate,
            show_all: false,
            query: query,
            customer_id: customerId,
            company_id: companyId,
            event_timing: eventTiming,
            sort_field: sortField,
            sort_direction: sortDirection,
            delivery_type: deliveryType,
            include_delivery_tickets: includeDeliveryTickets,
            setting_icon: deliveryTime,
            contact_categories: contactCategories,
            page,

            ...(prioritize_sticky && { prioritize_sticky: true }),
            ...(multilocationAllLocations && {
              multilocation_all_locations: true,
            }),
          }
        )
      )
        .map((response) => {
          const newState = Object.assign(
            {},
            {
              rentals: response.data.rentals,
            },
            response.data.meta && { pagination: response.data.meta.pagination }
          );
          return {
            type: FETCH_RENTAL_LIST_SUCCESS,
            payload: newState,
          };
        })
        .catch((error) =>
          Observable.of({
            type: EPIC_ERROR,
          })
        );
    });

export default fetchRentalListEpic;
