import {
  FETCH_RENTAL_LIST_REQUEST,
  FETCH_RENTAL_LIST_SUCCESS,
  PAGINATE_RENTAL_LIST_REQUEST,
  PAGINATE_RENTAL_LIST_SUCCESS,
  SET_RENTAL_FILTER,
  REQUEST_RENTAL_NAMES,
  RESET_RENTAL_NAMES,
  RECEIVE_RENTAL_NAMES,
  ADD_INFINITE_RENTALS_REQUEST,
  ADD_INFINITE_RENTALS_SUCCESS,
  ADD_INFINITE_RENTALS_FAILURE,
} from 'Constants/redux';

const INITIAL_STATE = {
  rentals: [],
  names: [],
  loading: false,
  fetchingNames: false,
  pagesRemaining: false,
  pagination: {},
  filter: {
    topLevelFilter: '',
    startDate: null,
    endDate: null,
    deliveryType: '',
    selectedDate: null,
    query: '',
    billingStatus: '',
    truckIds: '',
    salesperson: '',
    source: '',
    showAll: false,
    status: '',
    pickedStatus: '',
    checkedInStatus: '',
    contactCategories: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAGINATE_RENTAL_LIST_REQUEST:
    case FETCH_RENTAL_LIST_REQUEST:
    case ADD_INFINITE_RENTALS_REQUEST:
      return { ...state, loading: true };
    case ADD_INFINITE_RENTALS_FAILURE:
      return { ...state, loading: false };
    case FETCH_RENTAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        pagesRemaining:
          action.payload.rentals.length !==
          action.payload.pagination.totalObjects,
      };
    case ADD_INFINITE_RENTALS_SUCCESS:
      const totalRentals = [...state.rentals, ...action.payload.rentals];
      return {
        ...state,
        loading: false,
        rentals: totalRentals,
        pagination: action.payload.pagination,
        pagesRemaining:
          totalRentals.length !== action.payload.pagination.totalObjects,
      };
    case PAGINATE_RENTAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case SET_RENTAL_FILTER:
      return {
        ...state,
        filter: {...action.payload.filter},
      };
    case REQUEST_RENTAL_NAMES:
      return {
        ...state,
        fetchingNames: true,
      };
    case RECEIVE_RENTAL_NAMES:
      return {
        ...state,
        fetchingNames: false,
        names: action.names,
      };
    case RESET_RENTAL_NAMES:
      return {
        ...state,
        names: [],
      };
    default:
      return state;
  }
};
