import { ROOT_URL, saveError } from "Api";
import service from "Api/service";
import {
  ADD_DISPLAY_WEBSITE_REQUEST,
  REMOVE_DISPLAY_WEBSITE_REQUEST,
  FETCH_BUSINESS_REQUEST,
  FETCH_BUSINESS_FAILURE,
  FETCH_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_REQUEST,
  UPDATE_BUSINESS_ROUTING_REQUEST,
  UPDATE_BUSINESS_FAILURE,
  UPDATE_BUSINESS_SUCCESS,
  FETCH_DELIVERY_SETTING_REQUEST,
  FETCH_DELIVERY_SETTING_FAILURE,
  FETCH_DELIVERY_SETTING_SUCCESS,
  SET_BUSINESS_PROPS,
  SET_DEFAULT_SETUP_TIME,
  REQUEST_FOR_ACTIVATION,
  REQUEST_FOR_ACTIVATION_SUCCESS,
  REQUEST_FOR_ACTIVATION_FAILURE,
  HIDE_ALERTS_TRIAL_MSG_ON_CROSS,
  SHOULD_SHOW_ALERT_TRIAL,
  SHOULD_HIDE_ALERT_TRIAL,
  QB_ACCOUNTS_AUTHENTICATION_FAILURE,
  QB_ACCOUNTS_AUTHENTICATION_SUCCESS,
  QB_ACCOUNTS_AUTHENTICATION_REQUEST
} from "Constants/redux";

import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

import moment from "moment";
import { openSuccessSnackBar, setErrors } from "./dashboardActionCreators";

export const receiveBusiness = response => {
  return (dispatch, getState) => {
    dispatch({
      response: response.data,
      type: FETCH_BUSINESS_SUCCESS
    });
  };
};
export const setDefaultSetupTime = data => {
  return dispatch => {
    dispatch({
      payload: data,
      type: SET_DEFAULT_SETUP_TIME
    });
  };
};
export const setBusinessProps = newProps => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_BUSINESS_PROPS,
      payload: newProps
    });
  };
};

export const fetchBusiness = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_BUSINESS_REQUEST });
    service
      .get(`${ROOT_URL}/business/`)
      .then(response => {
        // Validate free trial of business

        const { business } = response.data;
        return dispatch({
          response: response.data,
          type: FETCH_BUSINESS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        dispatch({
          error,
          type: FETCH_BUSINESS_FAILURE
        });
      });
  };
};


export const showAlertIfQbAccountsNeedsAuth = (businessId, locationId) => {
  return (dispatch, getState) => {
    if (businessId !== null && locationId !== null) {
      dispatch({
        type: QB_ACCOUNTS_AUTHENTICATION_REQUEST
      });

      service
          .get(`${ROOT_URL}/quickbooks/expired_auths`)
          .then(response => {

            return dispatch({
              qb_re_auth_locations: response.data.qb_re_auth_locations,
              type: QB_ACCOUNTS_AUTHENTICATION_SUCCESS
            });
          })
          .catch(error => {
            return dispatch({
              qb_re_auth_locations: error.response.data.qb_re_auth_locations,
              type: QB_ACCOUNTS_AUTHENTICATION_FAILURE
            });
          });
    }
  };

}

export const fetchDeliverySetting = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_DELIVERY_SETTING_REQUEST });
    service
      .get(`${ROOT_URL}/delivery_setting/`)
      .then(response => {
        return dispatch({
          response: response.data,
          type: FETCH_DELIVERY_SETTING_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_DELIVERY_SETTING_FAILURE
        });
      });
  };
};

export const updateBusiness = data => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_BUSINESS_REQUEST });
    return service
      .patch(`${ROOT_URL}/business/`, data)
      .then(response => {
        dispatch({
          response: response.data,
          type: UPDATE_BUSINESS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        if (error.response.data) {
          dispatch(setErrors(error.response.data));
        }

        dispatch({
          error,
          type: UPDATE_BUSINESS_FAILURE
        });

        return Promise.reject(error);
      });
  };
};

export const requestForActivation = () => {

  return (dispatch, getState) => {
    dispatch({type: REQUEST_FOR_ACTIVATION});
    dispatch(openLoadingSpinner('please wait...'));
    return service
        .post(`${ROOT_URL}/business/activation_request`)
        .then(response => {
          dispatch({
            type: REQUEST_FOR_ACTIVATION_SUCCESS,
            response:response.data
          });
          dispatch(closeLoadingSpinner());
        })
        .catch(error => {
          console.log(error);
          saveError(error, getState());
          if (error.response.data) {
            dispatch(setErrors(error.response.data));
          }

          dispatch({
            error,
            type: REQUEST_FOR_ACTIVATION_FAILURE,
          });

          dispatch(closeLoadingSpinner());

          return Promise.reject(error);
        });
  };
};

export const updateBusinessRouting = data => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_BUSINESS_ROUTING_REQUEST });
    return service
      .patch(`${ROOT_URL}/business/`, data)
      .then(response => {
        dispatch({
          response: response.data,
          type: UPDATE_BUSINESS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        if (error.response.data) {
          dispatch(setErrors(error.response.data));
        }

        dispatch({
          error,
          type: UPDATE_BUSINESS_FAILURE
        });

        return Promise.reject(error);
      });
  };
};

export const addDisplayWebsite = data => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_DISPLAY_WEBSITE_REQUEST });
    return service
      .post(`${ROOT_URL}/business/display_websites/`, data)
      .then(response => {
        dispatch({
          response: response.data,
          type: UPDATE_BUSINESS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: UPDATE_BUSINESS_FAILURE
        });
        return Promise.reject(error);
      });
  };
};

export const removeDisplayWebsite = id => {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_DISPLAY_WEBSITE_REQUEST });
    return service
      .delete(`${ROOT_URL}/business/display_websites/${id}`)
      .then(response => {
        dispatch({
          response: response.data,
          type: UPDATE_BUSINESS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: UPDATE_BUSINESS_FAILURE
        });
        return Promise.reject(error);
      });
  };
};

export const hideAlertsTrialMsgOnCross = () => {
  return (dispatch, getState) => {
    dispatch({
      type: HIDE_ALERTS_TRIAL_MSG_ON_CROSS
    });
  };
};

export const shouldShowAlertTrial = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SHOULD_SHOW_ALERT_TRIAL
    });
  };
};

export const shouldHideAlertTrial = () => {
  return (dispatch, getState) => {
    dispatch({
      type: SHOULD_HIDE_ALERT_TRIAL
    });
  };
};
