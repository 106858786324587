import { combineReducers } from 'redux';
import customers from './customers';
import customer from './SingleCustomer';
import AuthReducer from './AuthReducer';
import CreditCardReducer from './CreditCardReducer';
import AlertReducer from './AlertReducer';
import MessageAlertReducer from './MessageAlertReducer';
import AdminAuthReducer from './AdminAuthReducer';
import AdminBusinessesReducer from './AdminBusinessesReducer';
import AdminReportsReducer from './AdminReportsReducer';
import ReportsReducer from './ReportsReducer';
import calendars from './calendars';
import dashboard from './dashboard';
import TutorialReducer from './TutorialReducer';
import ProductReducer from './ProductReducer';
import BusinessReducer from './BusinessReducer';
import LocationReducer from './LocationReducer';
import LocationListReducer from './LocationListReducer';
import ConnectDefaultReducer from './ConnectDefaultReducer';
import MarketplaceSettingReducer from './MarketplaceSettingReducer';
import RentalReducer from './RentalReducer';
import RentalListReducer from './RentalListReducer';
import RentalTransportListReducer from './RentalTransportListReducer';
import TruckRouteListReducer from './TruckRouteListReducer';
import OrderRouteListReducer from './OrderRouteReducer';
import TruckRouteReducer from './TruckRouteReducer';
import TruckListReducer from './TruckListReducer';
import RoutingScheduleReducer from './RoutingScheduleReducer';
import RentalInventoryListReducer from './RentalInventoryListReducer';
import UserReducer from './UserReducer';
import VenueReducer from './VenueReducer';
import CompanyReducer from './CompanyReducer';
import StaffMembersReducer from './StaffMembersReducer';
import StaffRolesReducer from './StaffRolesReducer';
import RecurrenceReducer from './RecurrenceReducer';
import ContactReducer from './ContactReducer';
import MessageableReducer from './MessageableReducer';
import CategoryReducer from './CategoryReducer';
import FeatureReducer from './FeatureReducer';
import VersionReducer from './VersionReducer';
import ComplianceReducer from './ComplianceReducer';
import SystemUpdateReducer from './SystemUpdateReducer';
import ConversationListReducer from './ConversationListReducer';
import ConversationReducer from './ConversationReducer';
import LoadingSpinnerReducer from './LoadingSpinnerReducer';
import ProgressBarReducer from './ProgressBarReducer';
import ProgressTrackerReducer from './ProgressTrackerReducer';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import GeofenceFilterReducer from './GeofenceFilterReducer';
import RentalTransportReducer from './RentalTransportReducer';
import CombinedInventoryReducer from './CombinedInventoryReducer';
import HelpSidebarReducer from './HelpSidebarReducer';
import FaqListReducer from './FaqListReducer';
import NotificationReducer from './NotificationReducer';
import InventoryReducer from './InventoryReducer';
import CircleOfDeathErrorReducer from './CircleOfDeathErrorReducer';
import PickListReducer from './PickListReducer';
import MaintenanceRecordReducer from './MaintenanceRecordReducer';
import RentalFormReducer from './rental_form';
import PricingTierReducer from './PricingTierReducer';
import RoleReducer from './RolesReducer';
import GeneralLedgerReducer from './GeneralLedgerReducer';
import FilterSettingsReducer from './FilterSettingsReducer';
import OperationReducer from './OperationReducer';
import TerminologyReducer from './TerminologyReducer';
import GroupedPicklistReducer from './GroupedPicklistReducer';
import TransactionReducer from './TransactionReducer';
import RentalStaffReducer from './RentalStaffReducer';
import ServiceTicketReducer from './ServiceTicketReducer';
import AvailabilityReducer from './AvailabilityReducer';
import RentalFormV2Reducer from './RentalFormV2Reducer.ts';
import FlowSheetsReducer from './FlowSheetsReducer.ts';
import NewRentalReducer from './NewRentalReducer.ts';
import EventReducer from './EventReducer.ts';

const mainReducer = combineReducers({
  customers,
  venues: VenueReducer,
  companies: CompanyReducer,
  customer,
  messageable: MessageableReducer,
  staffMembers: StaffMembersReducer,
  staffRoles: StaffRolesReducer,
  contacts: ContactReducer,
  calendars,
  dashboard,
  tutorial: TutorialReducer,
  conversationList: ConversationListReducer,
  conversation: ConversationReducer,
  categories: CategoryReducer,
  features: FeatureReducer,
  auth: AuthReducer,
  admin_auth: AdminAuthReducer,
  adminBusinesses: AdminBusinessesReducer,
  adminReports: AdminReportsReducer,
  reports: ReportsReducer,
  routing: routerReducer,
  products: ProductReducer,
  combinedInventory: CombinedInventoryReducer,
  availability: AvailabilityReducer,
  alerts: AlertReducer,
  messageAlerts: MessageAlertReducer,
  creditCard: CreditCardReducer,
  business: BusinessReducer,
  location: LocationReducer,
  locationList: LocationListReducer,
  connectDefault: ConnectDefaultReducer,
  marketplaceSetting: MarketplaceSettingReducer,
  user: UserReducer,
  rental: RentalReducer,
  recurrence: RecurrenceReducer,
  rentalsList: RentalListReducer,
  rentalTransportList: RentalTransportListReducer,
  truckRouteList: TruckRouteListReducer,
  orderRouteList: OrderRouteListReducer,
  truckRoute: TruckRouteReducer,
  truckList: TruckListReducer,
  routingSchedule: RoutingScheduleReducer,
  form: formReducer,
  rentalInventoryList: RentalInventoryListReducer,
  loadingSpinner: LoadingSpinnerReducer,
  version: VersionReducer,
  compliance: ComplianceReducer,
  systemUpdate: SystemUpdateReducer,
  progressBar: ProgressBarReducer,
  progressTracker: ProgressTrackerReducer,
  geofenceFilter: GeofenceFilterReducer,
  rentalTransport: RentalTransportReducer,
  helpSidebar: HelpSidebarReducer,
  faqList: FaqListReducer,
  notification: NotificationReducer,
  inventoryProduct: InventoryReducer,
  circleOfDeathError: CircleOfDeathErrorReducer,
  pickListStatuses: PickListReducer,
  maintenanceRecord: MaintenanceRecordReducer,
  rentalForm: RentalFormReducer,
  rentalFormV2: RentalFormV2Reducer,
  flowSheetsFilters: FlowSheetsReducer,
  pricingTier: PricingTierReducer,
  roles: RoleReducer,
  generalLedger: GeneralLedgerReducer,
  transactions: TransactionReducer,
  rentalStaff: RentalStaffReducer,
  serviceTicket: ServiceTicketReducer,
  filterSettings: FilterSettingsReducer,
  operation: OperationReducer,
  terminology: TerminologyReducer,
  originalTerminology: TerminologyReducer,
  groupedPicklist: GroupedPicklistReducer,
  newRental: NewRentalReducer,
  event: EventReducer,
});

export default mainReducer;
