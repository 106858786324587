import React from "react";
import { addPadding, renderDeliveryTime } from "HelperFunctions/delivery_ticket";

export default function RentalName({
  rental,
  lineBreak,
  skipParenthesis,
  inline
}) {
  if (inline) {
    return (
      <div>
        <div className="rentalRowName">
          {rental.name ? rental.name : "Untitled"}
          {"  "}{" "}
          <span className="rentalToken">
            {(skipParenthesis && `#${rental.token}`) || `(#${rental.token})`}
            {rental.type == 'DeliveryTicket' && (<b>-{addPadding(rental)}</b>)}
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="rentalRowName">
          {rental.name ? rental.name : "Untitled"}
          {"  "}
        </div>
        <span className="rentalToken">
          {(skipParenthesis && `#${rental.token}`) || `(#${rental.token})`}
          {rental.type == 'DeliveryTicket' && (<b>-{addPadding(rental)}</b>)}
        </span>
      </div>
    );
  }
}
