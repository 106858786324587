import { getAuthToken } from 'Api';
import service from 'Api/service';
import axios from 'axios';

// SerializedProducts

export default {
  showOne: (product, serialNumber) => {
    return axios.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/${serialNumber}`,
      {
        headers: getAuthToken(),
      }
    );
  },
  showBySerialNumber: (product, serialNumber) => {
    return axios.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/${serialNumber}/show_by_serial_number`,
      {
        headers: getAuthToken(),
      }
    );
  },
  fetchSerializedProductsForCheckOut: (product, rentalToken) => {
    return axios.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/available_to_check_out`,
      {
        params: {
          rental_token: rentalToken,
        },
        headers: getAuthToken(),
      }
    );
  },
  fetchSerializedProductsFromLink: (link) => {
    return axios.get(process.env.REACT_APP_API_DOMAIN + link, {headers: getAuthToken()})
  },
  fetchSerializedProductsForCheckIn: (product, rentalToken) => {
    return axios.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/available_to_check_in`,
      {
        params: {
          rental_token: rentalToken,
        },
        headers: getAuthToken(),
      }
    );
  },
  updateUsageUnit: (product, serialNumber, updatedUsageUnit) => {
    return axios.patch(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/${serialNumber}/update_usages`,
      updatedUsageUnit,
      {
        headers: getAuthToken(),
      }
    );
  },
  scanOneCheckIn: (pick_list_item, serialNumber) => {
    return axios.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/pick_list_items/${pick_list_item.id}/serialized_products/${serialNumber}/scan_check_in`,
      {
        headers: getAuthToken(),
      }
    );
  },
  printAll: (productId) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${productId}/serialized_products/print`
    );
  },
  generateSerializedProducts: (productId, selectedProducts = []) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${productId}/serialized_products/generate_serialized_products`,
      {
        serialized_products_ids: selectedProducts
      }
    );
  },
  printOne: (productId, serialNumber) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${productId}/serialized_products/${serialNumber}/print_single`
    );
  },
  itemInfo: (rentalToken, serialNumber, type) =>
    service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/rentals/${rentalToken}/search_by_serial_number`,
      {
        serial_number: serialNumber,
        type: type,
        headers: getAuthToken(),
      }
    ),
};
