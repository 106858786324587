import {
  FETCH_RENTAL_LIST_REQUEST,
  PAGINATE_RENTAL_LIST_REQUEST,
  SET_RENTAL_FILTER,
  REQUEST_RENTAL_NAMES,
  RECEIVE_RENTAL_NAMES,
  ADD_INFINITE_RENTALS_REQUEST,
  ADD_INFINITE_RENTALS_SUCCESS,
  ADD_INFINITE_RENTALS_FAILURE,
} from 'Constants/redux';
import { saveError } from 'Api';
import service from 'Api/service';
import { RESET_RENTAL_NAMES } from '../constants/redux';

export function setRentalFilter(filter, location) {
  return { type: SET_RENTAL_FILTER, payload: { filter } };
}

// Useful when we need to do an action (e.g. API call) with the updated filter.
export function setRentalFilterWithPromise(filter) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: SET_RENTAL_FILTER,
        payload: { filter },
      });

      resolve();
    });
}

export const loadRentals = (prioritize_sticky = false) => {
  return (dispatch, getState) => {
    const {
      topLevelFilter,
      numberPer,
      startDate,
      endDate,
      startDeliveryDate,
      endDeliveryDate,
      deliveryType,
      deliveryTime,
      selectedDate,
      showAll,
      query,
      billingStatus,
      truckIds,
      salesperson,
      source,
      status,
      pickedStatus,
      checkedInStatus,
      customerId,
      companyId,
      eventTiming,
      page,
      sortField,
      sortDirection,
      includeDeliveryTickets,
      contactCategories,
      departmentIds,
      pickListStatus,
    } = getState().rentalsList.filter;

    const { multilocationAllLocations } = getState().dashboard;

    let zone;
    startDate && (zone = -startDate.getTimezoneOffset() / 60);
    endDate && (zone = -endDate.getTimezoneOffset() / 60);
    startDeliveryDate && (zone = -startDeliveryDate.getTimezoneOffset() / 60);
    endDeliveryDate && (zone = -endDeliveryDate.getTimezoneOffset() / 60);
    selectedDate && (zone = -new Date(selectedDate).getTimezoneOffset() / 60);

    dispatch({
      type: FETCH_RENTAL_LIST_REQUEST,
      params: {
        filter: topLevelFilter,
        numberPer,
        startDate,
        endDate,
        startDeliveryDate,
        endDeliveryDate,
        deliveryType,
        deliveryTime,
        showAll,
        query,
        billingStatus,
        truckIds,
        salesperson,
        source,
        zone,
        status,
        pickedStatus,
        checkedInStatus,
        customerId,
        companyId,
        page,
        sortField,
        sortDirection,
        eventTiming,
        selectedDate: selectedDate,
        includeDeliveryTickets,
        contactCategories,
        departmentIds,
        pickListStatus,
        ...(prioritize_sticky && { prioritize_sticky: true }),
        ...(multilocationAllLocations && { multilocationAllLocations: true }),
      },
    });
  };
};

export const addInfiniteRentals = (link) => {
  return (dispatch, getState) => {
    const {
      status,
      numberPer,
      startDate,
      endDate,
      query,
      billingStatus,
      truckIds,
      salesperson,
      source,
      rentalStatus,
      pickedStatus,
      checkedInStatus,
      departmentIds,
      pickListStatus,
    } = getState().rentalsList.filter;
    dispatch({ type: ADD_INFINITE_RENTALS_REQUEST });

    let zone;
    startDate && (zone = -startDate.getTimezoneOffset() / 60);
    endDate && (zone = -endDate.getTimezoneOffset() / 60);

    service
      .post(process.env.REACT_APP_API_DOMAIN + link, {
        per: numberPer,
        filter: status,
        billing_status: billingStatus,
        truck_ids: truckIds,
        salesperson: salesperson,
        source: source,
        start_date: startDate,
        end_date: endDate,
        time_zone_offset: zone,
        rental_status: rentalStatus,
        picked_status: pickedStatus,
        checked_in_status: checkedInStatus,
        show_all: false,
        query: query,
        department_ids: departmentIds,
        pick_list_status: pickListStatus,
      })
      .then((response) => {
        dispatch({
          type: ADD_INFINITE_RENTALS_SUCCESS,
          payload: {
            rentals: response.data.rentals,
            pagination: response.data.meta.pagination,
          },
        });
      })
      .catch((error) => {
        dispatch({ type: ADD_INFINITE_RENTALS_FAILURE });
        saveError(error);
      });
  };
};

export function setRentalFilterAndReload(filter, prioritize_sticky = false) {
  return (dispatch, getState) => {
    dispatch(setRentalFilter(filter));
    dispatch(loadRentals(prioritize_sticky));
  };
}

export const paginateRentalList = (link) => {
  return (dispatch, getState) => {
    const {
      status,
      numberPer,
      startDate,
      endDate,
      query,
      billingStatus,
      truckIds,
      salesperson,
      source,
      rentalStatus,
      pickedStatus,
      checkedInStatus,
    } = getState().rentalsList.filter;

    dispatch({
      type: PAGINATE_RENTAL_LIST_REQUEST,
      params: {
        link,
        numberPer,
        status,
        startDate,
        endDate,
        query,
        billingStatus,
        truckIds,
        salesperson,
        source,
        rentalStatus,
        pickedStatus,
        checkedInStatus,
      },
    });
  };
};

const requestRentalNames = () => {
  return {
    type: REQUEST_RENTAL_NAMES,
  };
};

const receiveRentalNames = (names) => {
  return {
    type: RECEIVE_RENTAL_NAMES,
    names: names,
  };
};

export const resetRentalNames = () => {
  return {
    type: RESET_RENTAL_NAMES,
  };
};

export const fetchRentalNames = (customer_id = '') => {
  return (dispatch, getState) => {
    dispatch(requestRentalNames());
    return service
      .get(process.env.REACT_APP_API_DOMAIN + `/api/rentals/names`, {
        customer_id: customer_id,
      })
      .then((response) => {
        dispatch(receiveRentalNames(response.data.names));
      })
      .catch((error) => {
        saveError(error, getState());
      });
  };
};
