import React from 'react';
import { FormattedDate } from 'react-intl';
import CurrencyLabel from 'Utils/CurrencyLabel';

const DetailedAmountRefunded = (props) => {
  const { key, refund } = props;
  const feeValue =
    Number(refund.tapgoodsFee) +
    Number(refund.tapgoodsTax) +
    Number(refund.processingFee);

  let refundType;
  if (refund.refundType === 'electronic') {
    refundType = 'CC/ACH';
  } else if (refund.refundType === 'bank') {
    refundType = 'ACH';
  } else if (refund.refundType === 'cash') {
    refundType = 'Cash/Check';
  } else if (refund.refundType === 'pos') {
    refundType = 'POS';
  } else if (refund.refundType === 'credit_term') {
    refundType = 'Credit Term';
  } else if (refund.refundType === 'other') {
    refundType = `Refund Other: ${refund.refundTypeName}`;
  }

  return (
    <div key={key}>
      <label>Refund</label>
      <CurrencyLabel value={refund.amount} />
      <div className='grid rTable'>
        <div className='rTableHeading'>
          <div className='rTableRow'>
            <div className='sm rTableHead'>
              <FormattedDate
                value={refund.createdAt}
                year='numeric'
                month='numeric'
                day='numeric'
              />
            </div>
            <div className='rTableHead'>{refundType}</div>
            <div className='sm right rTableHead'>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedAmountRefunded;
