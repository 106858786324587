import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import classnames from 'classnames';
import { FormattedTime } from 'react-intl';
import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';

class PrintDeliveryManifest extends React.Component {
  state = {
    drivers: [],
    errors: '',
    timeZone: null,
  };

  fetchDeliveryManifest() {
    const id = this.props.match.params.id;
    const { setErrors } = this.props;

    service
      .get(`${ROOT_URL}/routing/truck_routes/${id}/delivery_manifest`)
      .then((response) => {
        const drivers = response.data;
        this.setState({
          drivers: drivers,
          timeZone: drivers[0]?.routes[0]?.schedule?.time_zone,
        });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
        setErrors(error.response.data);
      });
  }

  componentDidMount() {
    const { fetchTruckRoute } = this.props;
    fetchTruckRoute(this.props.match.params.id);
    this.fetchDeliveryManifest();
  }

  sanitizeStopAddress = (address = '') => {
    return address
      .split(', ')
      .filter((addressPart) => addressPart && addressPart.trim())
      .join(', ');
  };

  render() {
    const { drivers, timeZone, errors } = this.state;

    return (
      <div className='deliveryManifestPrintWrapper'>
        <div>
          <div className='content'>
            <div className='header'>
              <h1>Delivery Manifest</h1>
            </div>

            {drivers.length ? (
              drivers.map((driver, index) => (
                <div className='driverWrapper' key={index}>
                  <div className='drivers'>
                    <p>
                      {driver.employee
                        ? `Driver: ${driver.employee.first_name} ${driver.employee.last_name}`
                        : 'Driver: No Driver Assigned.'}
                    </p>
                    <p>{`Delivery Date: ${driver.delivery_date}`}</p>
                  </div>
                  <table className='driverTable'>
                    <tr>
                      <td className='blueFont va-top mt-15'>Truck(s)</td>
                      <td className='mt-15'>
                        {driver.trucks && (
                          <ul>
                            {driver.trucks.map((truck, index) => (
                              <li key={index}>{truck}</li>
                            ))}
                          </ul>
                        )}
                      </td>
                    </tr>
                    <tr className='mt-27 va-bottom blueFont'>
                      <td>Leave Warehouse</td>
                      <td className='blankSpace'></td>
                      <td>Lunch</td>
                      <td className='blankSpace'></td>
                      <td>Till</td>
                      <td className='blankSpace'></td>
                    </tr>
                    <tr className='mt-27'>
                      <td className='blueFont'>Run No</td>
                      <td className='va-bottom'>{driver.routes?.length}</td>
                    </tr>
                    <tr className='mt-27'>
                      <td className='blueFont va-top'>Helpers</td>
                      <td className='va-bottom' colSpan='5'>
                        {driver.helpers && (
                          <ul>
                            {driver.helpers.map((helper, index) => (
                              <li key={index}>{helper}</li>
                            ))}
                          </ul>
                        )}
                      </td>
                    </tr>
                  </table>

                  <div className='routes'>
                    <p>Route(s)</p>
                  </div>
                  <table className='bodyPadding'>
                    <tr className='routesHeader blueFont'>
                      <td width='10%'>Order</td>
                      <td width='20%'>Delivery Time</td>
                      <td width='20%'>Order Time</td>
                      <td width='20%'>Contact</td>
                      <td width='20%'>Address</td>
                      <td width='10%'>Delivery Method</td>
                    </tr>

                    {!!driver.routes &&
                      driver.routes.map((route, index) => (
                        <tbody
                          key={index}
                          className={classnames({
                            grayRuler: driver.routes.length > index + 1,
                            bodyPadding: true,
                            routesCell: true,
                          })}
                        >
                          <tr>
                            <td>
                              <b>{route.order_name}</b>
                              {route.order_id && `(#${route.order_id})`}
                            </td>
                            {route.delivery_method !== 'Custom' ? (
                              <td>
                                <FormattedTime
                                  value={route.start_date_time}
                                  timeZone={timeZone}
                                />
                                {'-'}
                                <FormattedTime
                                  value={route.end_date_time}
                                  timeZone={timeZone}
                                />
                                {route.delivery_time}
                              </td>
                            ) : (
                              <td></td>
                            )}

                            {route.delivery_method != 'Custom' ? (
                              <td>
                                <FormattedTime
                                  value={route.schedule.event_start_date_time}
                                  timeZone={timeZone}
                                />
                                {'-'}
                                <FormattedTime
                                  value={route.schedule.event_end_date_time}
                                  timeZone={timeZone}
                                />
                                {route.delivery_time}
                              </td>
                            ) : (
                              <td></td>
                            )}

                            <td>{route.contact}</td>
                            <td>{this.sanitizeStopAddress(route.address)}</td>
                            <td>{route.delivery_method}</td>
                          </tr>
                          {route.team_members && (
                            <tr className='noteRow'>
                              <td className='blueFont'>Team Member:</td>
                              <td colSpan='5'>{route.team_members}</td>
                            </tr>
                          )}
                          {route.has_set_by_time &&
                            route.set_by_time_date_time && (
                              <tr className='noteRow'>
                                <td className='blueFont'>Set By Time:</td>
                                <td colSpan='5'>
                                  {route.set_by_time_date_time}
                                </td>
                              </tr>
                            )}
                          {route.has_set_by_time && route.set_by_time_notes && (
                            <tr className='noteRow'>
                              <td colSpan='6' className='routesHeader'>
                                {route.set_by_time_notes}
                              </td>
                            </tr>
                          )}
                          {route.notes && route.notes.length > 0 && (
                            <tr>
                              <td className='blueFont'>Notes:</td>
                            </tr>
                          )}
                          {route.notes &&
                            route.notes.map((note, index) => (
                              <tr key={index} className='noteRow'>
                                <td colSpan='6' className='routesHeader'>
                                  {note.replace(/(<([^>]+)>)/gi, '')}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ))}
                  </table>
                </div>
              ))
            ) : (
              <div className='drivers'>
                <p>{errors}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { truckRoute } = state.truckRoute;
  return { truckRoute };
};

export default connect(mapStateToProps, actions)(PrintDeliveryManifest);
