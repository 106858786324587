import {
  CLEAR_EVENT,
  SUBMIT_EVENT_REQUEST,
  UPDATE_EVENT_STATE,
} from '../constants/redux';
import { cloneDeep } from '@apollo/client/utilities';
import { EventInput } from '../types/graphql';

const expectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getDefaultTitle = (date: Date) => {
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');

  const month = (date.getMonth() + 1).toString().padStart(2, '0');

  const year = date
    .getFullYear()
    .toString()
    .slice(-2);

  const formattedDate = `${month}/${day}/${year}`;

  return `New Event ${formattedDate}`;
};

const INITIAL_STATE: Partial<EventInput> & { loading: boolean } = {
  id: '',
  name: getDefaultTitle(new Date()),
  eventStartDateTime: new Date(),
  eventEndDateTime: new Date(),
  loading: false,
  rentalsCount: 0,
  active: true,
  timeZone: expectedTimezone,
  status: 'open',
};

const EventReducer = (state = cloneDeep(INITIAL_STATE), action: any) => {
  switch (action.type) {
    case UPDATE_EVENT_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case SUBMIT_EVENT_REQUEST:
      return {
        loading: true,
      };
    case CLEAR_EVENT:
      return {
        ...cloneDeep(INITIAL_STATE),
        eventStartDateTime: action.payload.eventStartDateTime,
        eventEndDateTime: action.payload.eventEndDateTime,
      };
    default:
      return state;
  }
};

export default EventReducer;
