import {
  FETCH_INVENTORY_AVAILABILITY_REQUEST,
  FETCH_INVENTORY_AVAILABILITY_SUCCESS,
  FETCH_INVENTORY_AVAILABILITY_FAILURE,
  SET_AVAILABILITY_LOADING_INVENTORY,
} from 'Constants/redux';

export const fetchInventoryAvailability = ({
  dateAttribute,
  startDate,
  endDate,
  products,
  bundles,
  accessories,
  addOns,
  shelfEnabled,
  rentalId,
  rentalStatuses,
  multilocation,
  formatHours,
}) => (dispatch, getState) => {
  const inventory = {
    dateAttribute,
    products,
    accessories,
    addOns,
    bundles,
    rentalStatuses,
    shelfEnabled,
    multilocation,
  };

  dispatch(setLoadingInventory(inventory));

  let fromDate = startDate;
  let toDate = endDate;
  if (formatHours) {
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);
    fromDate.setHours(0, 0, 0);
    toDate.setHours(23, 59, 59);
  }

  dispatch({
    type: FETCH_INVENTORY_AVAILABILITY_REQUEST,
    requestPayload: {
      startDate: fromDate,
      endDate: toDate,
      ...inventory,
      rentalStatuses,
      rentalId,
    },
  });
};

export const fetchInventoryAvailabilitySuccess = (payload) => (dispatch) =>
  dispatch({
    type: FETCH_INVENTORY_AVAILABILITY_SUCCESS,
    payload,
  });

export const fetchInventoryAvailabilityFailure = (payload) => (dispatch) =>
  dispatch({ type: FETCH_INVENTORY_AVAILABILITY_FAILURE, payload });

export const setLoadingInventory = (payload) => (dispatch) =>
  dispatch({
    type: SET_AVAILABILITY_LOADING_INVENTORY,
    payload,
  });
