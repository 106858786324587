import moment from 'moment-timezone';

export const addPadding = (deliveryTicket) => {
  return addPaddingToSequence(deliveryTicket.sequence);
};

export const addPaddingToSequence = (sequence) => {
  return sequence.toString().padStart(3, '0');
};

export const displayDeliveryTicketSequenceAndName = (deliveryTicket) => {
  const hasName = deliveryTicket.name;
  let result = addPadding(deliveryTicket);
  if (hasName) {
    result += ` - ${deliveryTicket.name}`;
  }
  return result;
};

export const displayDeliveryTicketSequenceAndRental = (
  deliveryTicket,
  token
) => {
  return `#${token}-${addPadding(deliveryTicket)}`;
};

export const renderDeliveryTime = (deliveryTicket) => {
  const convert = (input) => {
    return moment(input, 'HH:mm').format('h:mm A');
  };

  if (
    (deliveryTicket && deliveryTicket.deliveryType == 'default_delivery') ||
    deliveryTicket.deliveryType == 'custom_delivery' ||
    deliveryTicket.deliveryType == 'customer_pick_up'
  ) {
    const { startTime, endTime } = getTimeByStatus(deliveryTicket);

    if (startTime && endTime) {
      return (
        convert(String(new Date(startTime)).substring(16, 21)) +
        ' - ' +
        convert(String(new Date(endTime)).substring(16, 21))
      );
    } else {
      return '7:00 AM - 12:00 PM';
    }
  } else if (deliveryTicket && deliveryTicket.deliveryType == 'ship') {
    const startTime =
      deliveryTicket.endWindowBeginning || deliveryTicket.endDateTime;

    if (startTime && deliveryTicket.endDateTime) {
      return (
        convert(String(new Date(startTime)).substring(16, 21)) +
        ' - ' +
        convert(String(new Date(deliveryTicket.endDateTime)).substring(16, 21))
      );
    } else {
      return '7:00 AM - 12:00 PM';
    }
  } else {
    return null;
  }
};

// Methods to filter items (Currentlty not used as reducer includes those elements)
export const filterPickListElements = (elements, dtId) => {
  if (elements && dtId == null) return elements;

  return elements
    ? elements.filter((element) => element.deliveryTicketId == dtId)
    : [];
};
// end filter items methods

export const checkIsAllDTsHaveSameStatus = (dTStatuses, selectedStatus) =>
  dTStatuses.every((dt) => dt === selectedStatus);
export const checkIsAnyDTHaveSelectedStatus = (dTStatuses, selectedStatus) =>
  dTStatuses.includes(selectedStatus);

export const findDeliveryTicketById = (deliveryTickets, deliveryTicketId) => {
  return deliveryTickets && deliveryTicketId
    ? deliveryTickets.find((dt) => dt.id === +deliveryTicketId)
    : null;
};

export const setUrlForScrollBarDeliveryTickets = (
  currentUrl,
  selectedDeliveryTicketId,
  rentalToken
) => {
  return currentUrl.endsWith('/deliveryTicketsPickList') ||
    currentUrl.endsWith('/pickList')
    ? `/orders/rentals/${rentalToken}/deliveryTickets/${selectedDeliveryTicketId}/pickList`
    : `/orders/rentals/${rentalToken}/deliveryTickets/${selectedDeliveryTicketId}`;
};

const deliveryOutStatuses = ['quote', 'reservation'];

export const getTimeByStatus = (deliveryTicket) => {
  let startTime = deliveryTicket.endWindowBeginning;
  let endTime = deliveryTicket.endDateTime;

  if (deliveryOutStatuses.includes(deliveryTicket.status)) {
    startTime = deliveryTicket.startDateTime;
    endTime = deliveryTicket.startWindowFinish;
  }

  return {
    startTime,
    endTime,
  };
};

export const getDateByStatus = (deliveryTicket) => {
  return deliveryOutStatuses.includes(deliveryTicket.status)
    ? deliveryTicket.startDateTime
    : deliveryTicket.endDateTime;
};
